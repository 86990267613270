import React, { useEffect } from "react"

import {
    Layout,
    useTranslate,
} from "@solublestudio/gatsby-theme-soluble-source"

export default function Company({ pageContext }) {
    const translate = useTranslate()
    const company = pageContext.pageData

    useEffect(() => {
        if (typeof window !== "undefined" && pageContext.clientRedirect) {
            window.location.href = pageContext.clientRedirect
        }
    }, [pageContext.clientRedirect])

    return (
        <Layout customSeo={pageContext.seo}>
            <h1>{company.name}</h1>
            {!!company.longDescription &&
                (company.longDescription.indexOf("<p") !== -1 ? (
                    <div>{company.longDescription}</div>
                ) : (
                    <p>{company.longDescription}</p>
                ))}
            <ul>
                <li>
                    <strong>{translate["company.modal.status"].text}</strong>:{" "}
                    {company.status}
                </li>
                <li>
                    <strong>{translate["company.modal.hq"].text}</strong>:{" "}
                    {company.headquarters}
                </li>
            </ul>

            {company.deals && company.deals.length
                ? company.deals.map((deal, i) =>
                      deal.startDate ? (
                          <div key={i}>
                              <h3>{deal.startDate.split("-")[0]}</h3>
                              <ul>
                                  <li>
                                      <strong>
                                          {translate["company.modal.type"].text}
                                      </strong>
                                      : {deal.type}
                                  </li>
                                  <li>
                                      <strong>
                                          {
                                              translate[
                                                  "company.modal.investment"
                                              ].text
                                          }
                                      </strong>
                                      : {deal.cashFormatted}€
                                  </li>
                              </ul>
                          </div>
                      ) : null
                  )
                : null}
            {company.website ? (
                <a
                    title={company.name}
                    href={company.website}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {translate["company.modal.button.website"].text}
                </a>
            ) : null}
        </Layout>
    )
}
